

.container {
    display: flex;
    justify-content: center;
    height: 80vh;
  }
  
  .box1 {
    margin-top: 100px;
    width: 80%;
    height: 250px;
    background-color:#3b4556;
    display: flex;
   
    justify-content: center;
  }
  
  .box2 {
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 300px;
    background-color: #e0e0e0;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }


  /* Apply responsive styles */
  @media (max-width: 768px) {
    .box2 {
        top:76%;

      /* position: static; */
      /* margin-top: 20px; */
    }

  } 